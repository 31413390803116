body {
    background-color: #282c34;
    color: white;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.Index-aside {
    padding-left: 2rem;
    padding-right: 2rem;
}
.Index-main {
    padding-left: 2rem;
    padding-right: 2rem;
}

.Index-link {
    color: #61dafb;
}

.Index-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: start;
    align-items: flex-start;
    justify-content: center;
}

.Paragraph-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: start;
    align-items: flex-start;
    justify-content: center;
    padding-left: 2rem;
    padding-right: 2rem;
    height: 100%;
}

.Paragraph-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 3rem;
    font-weight: bolder;
    color: rgb(255, 128, 0);
    filter: drop-shadow(.2rem .2rem .1rem black)
}

.Paragraph-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 1rem;
    font-weight: normal;
    color: white;
}