.Header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 2rem;
    color: rgb(255, 128, 0);
    filter: drop-shadow(.2rem .2rem .1rem black)
}

.Header-item {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.Header-logo {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 2rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.Header-link {
    text-decoration: none;
    color: rgb(255, 128, 0);
}

.Header-active {
    color: #61dafb;
}

.Header-link:hover {
    color: #61dafb;
}