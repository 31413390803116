.Art-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 3rem;
    font-weight: bolder;
    color: rgb(255, 128, 0);
    filter: drop-shadow(.15rem .15rem .1rem black)
}

.ArtImage-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    text-align: start;
    align-items: flex-start;
    justify-content: flex-start;
}

.ArtCollection-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 2rem;
    font-weight: bolder;
    color: rgb(255, 128, 0);
    filter: drop-shadow(.15rem .15rem .1rem black)
}

.ArtImage-header {
    font-size: 1rem;
    font-weight: 'lighter';
    color: rgb(255, 128, 0);
    filter: drop-shadow(.1rem .1rem .1rem black)
}

.ArtImage {
    padding-left: 2rem;
    padding-right: 2rem;
}